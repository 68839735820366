import React from "react"
import { Link } from "gatsby"
// import { GatsbyImage } from "gatsby-plugin-image"

const RelatedPosts = props => {
  console.log("asdasd");
  console.log(props);
  return (
    <section class="related-posts">
      <h3>You may also like...</h3>
      <div>
        <ul>
          {props.related.edges.map(post => (
            <li><Link key={post.node.id} to={post.node.frontmatter.path}>

              <p>{post.node.frontmatter.title}</p>
            </Link></li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default RelatedPosts;

/*
const RelatedPosts = props => (
  <section>
    <h2>You may also like...</h2>
    <div>
      {props.related.edges.map(post => (
        <Link key={post.node.id} to={post.node.fields.slug}>
          <GatsbyImage image={post.node.frontmatter.featuredImage.childImageSharp.gatsbyImageData} />
          <p>{post.node.frontmatter.title}</p>
        </Link>
      ))}
    </div>
  </section>
)
export default RelatedPosts
*/